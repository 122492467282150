








import { Component, Vue } from 'vue-property-decorator';
import { ArticleGetList, ArticleId } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';

@Component({
    name: 'Introduce',
    components: {}
})
export default class Introduce extends Vue {

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 4,
        Expressionable: []
    };

    private mounted() {
        this.init();
    }

    private async init() {
        try {
            this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 16, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                this.draw(DataList[0]);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async draw(row) {
        try {
            const { Data } = await ArticleId(row.ArticleId);
            const article: any = document.getElementById('articlej');
            article.innerHTML = '';
            article.appendChild(this.parseDom(Data.ArticleBody));
        } catch (error) {
            console.warn(error);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }
}
