
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetStationTypeList } from '@/api/select';

// 自提点类型

@Component({
    name: 'SelfDeliveryType',
    components: {}
})
export default class SelfDeliveryType extends Vue {
    @Prop({ type: [Number, String], default: '' }) public value!:any;

    // 是否需要主动触发加载
    @Prop({ type: Boolean, default: true }) private isAuto: boolean;

    public options: Array<any> = [];

    private mounted() {
        if (this.isAuto) {
            this.init();
        }
    }

    private async init() {
        try {
            const params: any = {};
            const { Data } = await GetStationTypeList(params);
            this.options = Data;
        } catch (err) {
            console.warn(err);
            this.handleChange(null);
        }
    }

    public handleChange(val: any) {
        this.$emit('input', val);
        this.$emit('change');
    }
}
