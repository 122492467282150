



import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import { CarryCountryList, CarryProvinceList, CarryCityList, CarryAreaList } from '@/api/select';
@Component({
    name: 'CarryRegion',
    components: {}
})
export default class CarryRegion extends Vue {
    @Prop({ type: [String, Number, Array], default: () => [] }) public value: any;
    @Prop({ type: [String, Number], default: '' }) private StationTypeId: any;

    @Ref('cascader') private readonly cascaderRef!: any;

    public props = {
        label: 'Text',
        value: 'Value',
        lazy: true,
        self: this,
        async lazyLoad(node, resolve) {
            try {
                const { level, value } = node;
                const data = await this.self.Request(level, value);
                resolve(data);
            } catch (err) {
                console.warn(err);
            }
        }
    };

    private async Request(level: number | string, code: string) {
        try {
            let requestAddres: any = '';
            const params: any = {};
            switch (level) {
                case 0:
                case '0':
                    requestAddres = CarryCountryList;
                    break;
                case 1:
                case '1':
                    params.CountryId = code;
                    requestAddres = CarryProvinceList;
                    break;
                case 2:
                case '2':
                    params.ProvinceId = code;
                    requestAddres = CarryCityList;
                    break;
                case 3:
                case '3':
                    params.CityId = code;
                    requestAddres = CarryAreaList;
                    break;
                default:
                    requestAddres = () => {
                        return { Data: [] };
                    };
                    console.warn('获取请求地址出错');
            }
            if (this.StationTypeId === '') {
                return;
            }
            params.StationTypeId = this.StationTypeId;
            const { Data } = await requestAddres(params);
            Data.map((item) => {
                if (level === 3) {
                    item.leaf = true;
                } else {
                    item.leaf = !item.Leaf;
                }
                return item;
            });
            return Data;
        } catch (err) {
            console.warn(err);
        }
    }

     public handleChange(arr) {
         this.$emit('input', arr);
         const Nodes: any = this.cascaderRef.getCheckedNodes();
         if (Nodes.length) {
            this.$emit('change', Nodes[0].pathLabels);
         } else {
            this.$emit('change', []);
         }
    }

    public formatData(arr: Array<object>) {
        try {
            const parmas = {};
            const str = ['CountryId', 'ProvinceId', 'CityId', 'AreaId'];
            arr.map((item, index) => {
                const idx = str[index];
                parmas[idx] = item;
                return item;
            });
            return parmas;
        } catch (err) {
            console.warn(err);
        }
    }
}
