








import { Component, Vue } from 'vue-property-decorator';
import ThirdSelfPromotion from './third-self-promotion.vue';
@Component({
    name: 'SelfPromotion',
    components: {
        ThirdSelfPromotion
    }
})
export default class SelfPromotion extends Vue {
    private mounted() {
        // aa
    }
}
