








import { Component, Vue } from 'vue-property-decorator';
import agreement from '@/views/agreement/index.vue';

@Component({
    name: 'Terms',
    components: {
        agreement
    }
})
export default class Terms extends Vue {
    private mounted() {
        // aa
    }
}
