










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Teaching',
    components: {}
})
export default class Teaching extends Vue {
    @Prop({
        type: String,
        default: () => ''
    })
    public step: any;

    @Prop({
        type: String,
        default: () => ''
    })
    public title!: any;

    private mounted() {
        // aa
    }
}
