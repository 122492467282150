








import { Component, Vue } from 'vue-property-decorator';

import TitleIndicator from './component/title-indicator.vue';
import { ArticleGetList, ArticleId } from '@/api/api';
import { searchConditionalTypeEnum } from '@/utils/type-enum';

@Component({
    name: 'Teaching',
    components: {
        TitleIndicator
    }
})
export default class Teaching extends Vue {
    // public data: any = [
    //     { src: '/teaching/teaching01.png', index: '1' },
    //     { src: '/teaching/teaching02.png', index: '2' },
    //     { src: '/teaching/teaching03.png', index: '3' },
    //     { src: '/teaching/teaching04-1.png', index: '4-1' },
    //     { src: '/teaching/teaching04-2.png', index: '4-2' },
    //     { src: '/teaching/teaching04-3.png', index: '4-3' },
    //     { src: '/teaching/teaching04-4.png', index: '4-4' },
    //     { src: '/teaching/teaching04-5.png', index: '4-5' },
    //     { src: '/teaching/teaching05.png', index: '5' }
    // ];

    public pageParams: any = {
        PageIndex: 1,
        PageSize: 4,
        Expressionable: []
    };

    private mounted() {
        this.init();
    }

    private async init() {
        try {
            this.pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 17, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(this.pageParams);
            if (DataList.length) {
                this.draw(DataList[0]);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private async draw(row) {
        try {
            const { Data } = await ArticleId(row.ArticleId);
            const article: any = document.getElementById('articlel');
            article.innerHTML = '';
            article.appendChild(this.parseDom(Data.ArticleBody));
        } catch (error) {
            console.warn(error);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }
}
