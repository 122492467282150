var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"third-self-promotion"},[_c('div',{staticClass:"layout-step-box"},[_c('div',{staticClass:"search-box"},[_c('div',{staticStyle:{"margin-right":"16px"}},[_c('div',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t('transport.SelfPromotion.lable1')))]),_c('ywhz-self-delivery-type',{staticStyle:{"width":"100%"},attrs:{"clearable":""},on:{"change":_vm.DeliveryTypeChange},model:{value:(_vm.formData.SelfType),callback:function ($$v) {_vm.$set(_vm.formData, "SelfType", $$v)},expression:"formData.SelfType"}})],1),(_vm.isShowDeliveryType)?_c('div',[_c('div',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t('transport.SelfPromotion.lable2')))]),_c('ywzh-carry-region',{staticStyle:{"width":"100%"},attrs:{"StationTypeId":_vm.formData.SelfType,"clearable":""},on:{"change":_vm.init},model:{value:(_vm.formData.AddressArr),callback:function ($$v) {_vm.$set(_vm.formData, "AddressArr", $$v)},expression:"formData.AddressArr"}})],1):_vm._e()]),_c('div',{staticClass:"table-box"},[_c('el-table',{attrs:{"data":_vm.list,"stripe":true,"border":true,"header-cell-style":{
                    'backgroundColor': '#F5F7FA',
                    'color': '#909399',
                    'fontSize': '14px',
                    'fontWeight': 'normal'
                },"cell-style":{
                    fontSize: '14px',
                    color: '#262626'
                },"height":"100%"}},[_c('el-table-column',{attrs:{"label":_vm.$t('transport.SelfPromotion.lable1'),"prop":"StationTypeName","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('transport.SelfPromotion.lable3'),"prop":"StationName","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('transport.SelfPromotion.lable5'),"prop":"Tel","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('transport.SelfPromotion.lable6'),"prop":"Address","align":"center"}}),_c('el-table-column',{attrs:{"label":((_vm.$t('transport.SelfPromotion.lable7')) + "(Kg)"),"prop":"MaxWeight","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$t('transport.SelfPromotion.lable8'),"prop":"ServiceTime","align":"center"}})],1)],1),_c('div',{staticClass:"page-box"},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":_vm.Total},on:{"current-change":_vm.currentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }